import WinningNumbersContainer from '@/components/WinningNumbersContainer';
import { GameConfig } from '@/config/game';
import { OddsVariant } from '@/config/odds/OddsVariant';
import { fromTimestamp } from '@/utilities/dateUtils';
import apiRequest from './apiRequest';

export async function fetchWinningNumbers(
  variant: OddsVariant,
  gameConfig?: GameConfig,
  date?: number,
  showTwo?: boolean
): Promise<WinningNumbersContainer> {
  let winningNumbers: any;
  if (gameConfig) {
    const apiUrls = gameConfig.apiUrls;
    const apiLast = apiUrls ? apiUrls.winningNumbers || null : null;
    const apiDate = apiUrls ? apiUrls.winningNumbersDate || null : null;

    try {
      if (
        variant === OddsVariant.Lotto6aus49 &&
        apiLast &&
        apiDate &&
        Array.isArray(apiLast) &&
        showTwo
      ) {
        const promises = (apiLast as string[]).map((api) => apiRequest(api));
        winningNumbers = await Promise.all(promises);
        winningNumbers = winningNumbers.sort(
          WinningNumbersContainer.sortWinningNumbers
        );
      } else if (apiDate && apiLast) {
        if (date) {
          winningNumbers = await apiRequest(
            `${apiDate}/${+fromTimestamp(date)}`
          );
        } else {
          if (Array.isArray(apiLast)) {
            const [first, second] = await Promise.all([
              apiRequest(apiLast[0]),
              apiRequest(apiLast[1]),
            ]);
            winningNumbers = first.drawDate > second.drawDate ? first : second;
          } else {
            winningNumbers = await apiRequest(apiLast);
          }
        }

        winningNumbers = Array.isArray(winningNumbers)
          ? winningNumbers[0]
          : winningNumbers;
      }
    } catch (error) {
      console.error('WinningNumbersContainer - fetchData failed', error);
    }
  }

  return winningNumbers;
}
