import { WinningNumbers } from '@/components/WinningNumbers';
import type { GameConfig } from '@/config/game';
import { OddsVariant } from '@/config/odds/OddsVariant';
import { fetchWinningNumbers } from '@/helpers/fetchWinningNumbers';
import { getGameByShortname } from '@/helpers/getGameByShortname';
import React from 'react';

export interface WinningNumbersContainerProps {
  date?: number;
  variant: OddsVariant;
  showLogoHeader?: boolean;
  showLargeHeader?: boolean;
  showFooter?: boolean;
  showDate?: boolean;
  showMore?: boolean;
  showTwoDraws?: boolean;
  small?: boolean;
  clickable?: boolean;
  setDrawDate?: (date: Date) => void;
}

interface WinningNumbersContainerState {
  showTwo: boolean;
  winningNumbers?: any;
}

export default class WinningNumbersContainer extends React.Component<
  WinningNumbersContainerProps,
  WinningNumbersContainerState
> {
  static Variant = OddsVariant;
  private gameConfig: GameConfig | undefined;

  constructor(props) {
    super(props);
    this.gameConfig = getGameByShortname(props.variant);
    this.state = {
      showTwo: props.date ? false : this.props.showTwoDraws || false,
      winningNumbers: null,
    };
  }

  static sortWinningNumbers(a: any, b: any): number {
    return typeof a.drawDate !== 'undefined' &&
      typeof b.drawDate !== 'undefined'
      ? b.drawDate - a.drawDate
      : 0;
  }

  componentDidMount() {
    if (this.state.winningNumbers === null) {
      this.fetchData(this.props.variant).then();
    }
  }

  render(): JSX.Element | null {
    const { showTwo } = this.state;
    const winningNumbers = this.state.winningNumbers;

    if (this.props.variant !== OddsVariant.Lotto6aus49) {
      return this.renderComponent(winningNumbers);
    } else {
      return showTwo && winningNumbers !== null ? (
        <div className="WinningNumbersContainer">
          {winningNumbers.map((winningNumbers, i) => (
            <div className="WinningNumbersContainer__item" key={i}>
              {this.renderComponent(winningNumbers)}
            </div>
          ))}
        </div>
      ) : (
        this.renderComponent(
          Array.isArray(winningNumbers) ? winningNumbers[0] : winningNumbers
        )
      );
    }
  }

  private async fetchData(variant: OddsVariant): Promise<void> {
    const date = this.props.date;
    let winningNumbers: any = await fetchWinningNumbers(
      variant,
      this.gameConfig,
      date,
      this.state.showTwo
    );
    if (winningNumbers?.drawDate) {
      this.setState({ winningNumbers });
      if (this.props.setDrawDate) {
        this.props.setDrawDate(new Date(winningNumbers.drawDate));
      }
    }
  }

  private renderComponent(winningNumbers: any): JSX.Element | null {
    return this.props.variant ? (
      <WinningNumbers
        variant={this.props.variant}
        winningNumbers={winningNumbers}
        showLogoHeader={
          this.props.showLogoHeader ||
          (this.props.showLogoHeader &&
            this.props.variant === OddsVariant.GluecksSpirale) ||
          false
        }
        showLargeHeader={
          this.props.showLargeHeader ||
          (this.props.showLargeHeader &&
            this.props.variant === OddsVariant.GluecksSpirale) ||
          false
        }
        showFooter={this.props.showFooter || false}
        showDate={this.props.showDate || false}
        showMore={this.props.showMore || false}
        small={this.props.small || false}
        clickable={this.props.clickable || false}
      />
    ) : null;
  }
}
